import {useState} from 'react'; 
import React from 'react';
import theme from './theme';

const handleHover = () => {
  buttonStyle.color = '#ffffff';
};

const Button = ({onClick, children}) => {
  const [isHover, setIsHover] = useState(false);
  const [mostrarMensaje, setMostrarMensaje] = useState(false);

  const buttonStyle = {
    position: 'absolute',
    zIndex: 100,
    top: 210,
    right: 12,
    width: '32px',
    height: '32px',
    color: theme.primaryBtnColorper,
    backgroundColor: theme.primaryBtnBgdper,
    cursor: 'pointer',
    border: 0,
    borderRadius: '3px',
    fontSize: '15px',
  };

  const divStyle = {
    position: 'absolute',
    display: "flex",
    zIndex: 100,
    top: 210,
    right: 55,
    width: '80px',
    height: '32px',
    cursor: 'pointer',
    border: 3,
    borderRadius: '2px',
    backgroundColor: theme.primaryDivBgdper,
    justifyContent: "center",
    alignItems: "center",
  };

  const messegeStyle = {
    fontSize: '10px',
    color: theme.primaryBtnColorper,
    textAlign: "center",
    fontFamily: "ff-clan-web-pro",
  }

  const handleMouseEnter = () => {
    setIsHover(true);
    setMostrarMensaje(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setMostrarMensaje(false);
  };

  return(
    <div>
    <button style={buttonStyle} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>{children}</button>
    {mostrarMensaje && (
      <div>
        <div style={divStyle}>
          <p style={messegeStyle}>
            Export Map
          </p>
        </div>
      </div>
      )}
    </div>
  )
};

export default Button;
