import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import axios from "axios";
import keycloak from "./keycloak-config";
import theme from "./theme";

const ExportMap = ({ onSubmit, onSubmitSave }) => {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [mostrarMensaje, setMostrarMensaje] = useState({ saveButton: false, saveAsButton: false });
  const [uid, setUid] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.API_URL}/groups/getAll`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then(({ data }) => {
        const groups = data.value;
        setGroups(groups);
      })
      .catch((error) => {
        console.error("Error fetching groups", error);
      });

    const handleUidReceived = (event) => {
      const { uid } = event.detail;
      console.log("event.detail", event.detail);
      setUid(uid);
    };

    window.addEventListener('uidReceived', handleUidReceived);

    return () => {
      window.removeEventListener('uidReceived', handleUidReceived);
    };
  }, []);

  const handleMouseEnter = (button) => {
    const messages = {
      saveButton: { saveButton: true },
      saveAsButton: { saveAsButton: true },
    };
    if (messages[button]) setMostrarMensaje(messages[button]);
    console.log(mostrarMensaje);
  };

  const handleMouseLeave = (button) => {
    const messages = {
      saveButton: { saveButton: false },
      saveAsButton: { saveAsButton: false },
    };
    if (messages[button]) setMostrarMensaje(messages[button]);
  };
  
  const saveMap = async () => {
    try {
      console.log("saveMap ", uid);
      onSubmitSave(uid);
    } catch (error) {
      console.error("Error submitting form", error);
    }
  }

  const messegeStyle = {
    fontSize: '10px',
    color: theme.primaryBtnColorper,
    textAlign: "center",
    fontFamily: "ff-clan-web-pro",
  }
  const divStyle = {
    position: 'absolute',
    display: "flex",
    zIndex: 100,
    top: mostrarMensaje.saveAsButton ? 246 : 284,
    right: 55,
    width: '80px',
    height: '32px',
    cursor: 'pointer',
    border: 3,
    borderRadius: '2px',
    backgroundColor: theme.primaryDivBgdper,
    justifyContent: "center",
    alignItems: "center",
  };
  const buttonStyle = {
    position: "absolute",
    zIndex: 100,
    top: 246,
    right: 12,
    padding: "0px",
    width: "32px",
    height: "32px",
    borderRadius: "0",
    color: theme.primaryBtnColorper,
    backgroundColor: theme.primaryBtnBgdper,
  };
  const buttonStyle2 = {
    position: "absolute",
    zIndex: 100,
    top: 284,
    right: 12,
    padding: "0px",
    width: "32px",
    height: "32px",
    borderRadius: "0",
    color: theme.primaryBtnColorper,
    backgroundColor: theme.primaryBtnBgdper,
  };
  const select = {
      maxWidth:"30vw"
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    console.log("group", group)
    formData.append("groups", group);

    try {
      onSubmit(formData);
      handleClose();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <>
      <IconButton
        aria-label="add a map"
        onClick={handleClickOpen}
        onMouseOver={() => handleMouseEnter("saveAsButton")}
        onMouseLeave={() => handleMouseLeave("saveAsButton")}
        style={buttonStyle}
      >
        <SaveIcon />
      </IconButton>
      {uid && (
      <IconButton
        aria-label="save map"
        onClick={saveMap}
        onMouseOver={() => handleMouseEnter("saveButton")}
        onMouseLeave={() => handleMouseLeave("saveButton")}
        style={buttonStyle2}
      >
        <SaveAltIcon />
      </IconButton>
      )}
      {(mostrarMensaje.saveAsButton || mostrarMensaje.saveButton) && (
        <div>
          <div style={divStyle}>
            <p style={messegeStyle}>
              {mostrarMensaje.saveAsButton ? "Guardar como" : "Guardar"}
            </p>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleFormSubmit,
        }}
      >
        <DialogTitle>Almacenar mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="normal"
            id="title"
            name="title"
            label="Titulo"
            type="text"
            fullWidth
            variant="outlined"
          ></TextField>

          <TextField
            autoFocus
            required
            margin="normal"
            id="description"
            name="description"
            label="Descripción"
            type="text"
            fullWidth
            variant="outlined"
            multiline
          ></TextField>

          <TextField
            margin="normal"
            id="select-group"
            select
            value={group}
            onChange={handleGroupChange}
            label="Grupos"
            helperText="Selecciona un grupo para el mapa"
            SelectProps={{
              multiple: true,
            }}
            style={select}
          >
            {groups?.map((option) => (
              <MenuItem key={option.uuid} value={option.uuid}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportMap;
